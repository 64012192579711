<template>
  <div>
    <b-card class="mb-2" no-body>
      <div class="m-2">
        <b-row class="mb-1">
          <b-col cols="12">
            <span class="card-title">Select Role</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="8" md="10">
            <HeroVueSelect
              id="id"
              v-model="roleId"
              :label="null"
              :clearable="false"
              :options="$store.getters['RoleOptions/getOptionsById']"
              @input="doTableFilter(0)"
            />
          </b-col>
          <b-col cols="12" sm="4" md="2">
            <HeroButtonModal
              v-if="$can('Edit', 'Roles')"
              v-b-modal="'role-create-modal'"
              type="button"
              variant="primary"
            >
              Add New Role
            </HeroButtonModal>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card v-if="roleId" class="mb-2" no-body>
      <!-- !! Table Header -->
      <div class="m-2">
        <b-row>
          <b-col cols="12" sm="8" md="10">
            <span
              v-if="$can('Edit', 'Roles')"
              v-b-modal="'role-edit-modal'"
              class="card-title"
            >
              {{ $store.getters['RoleOptions/getRoleName'] }} <feather-icon icon="Edit2Icon" />
            </span>
            <span
              v-else
              class="card-title"
            >
              {{ $store.getters['RoleOptions/getRoleName'] }}
            </span>
          </b-col>
          <b-col cols="12" sm="4" md="2">
            <HeroButtonModal
              v-if="$can('Edit', 'Roles')"
              v-b-modal="'role-add-permission-modal'"
              type="button"
              variant="primary"
            >
              Add Permission To Role
            </HeroButtonModal>
          </b-col>
        </b-row>
      </div>

      <!-- !! Table -->
      <b-row class="my-0">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="doTableSort"
          >
            <!-- !! Action Column -->
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none action-button"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('Edit', 'Roles')"
                  @click="doDeletePermission(data.item.name)"
                >
                  <div class="text-danger">
                    <feather-icon
                      icon="MinusCircleIcon"
                      class="mr-50"
                    />
                    <span>Remove Permission</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>

    <RoleCreateModal @success="doFetchRoleOptions" />
    <RoleEditModal :role-id="roleId" @success="doFetchRoleOptions" />
    <RoleAddPermissionModal :role-id="roleId" @success="doLoadData" />
  </div>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import SweetAlert from '@/services/SweetAlert'
import RoleAddPermissionModal from '@/views/heroai/settings/roles/components/RoleAddPermissionModal.vue'
import RoleCreateModal from '@/views/heroai/settings/roles/components/RoleCreateModal.vue'
import RoleEditModal from '@/views/heroai/settings/roles/components/RoleEditModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    HeroButtonModal,
    HeroVueSelect,
    RoleAddPermissionModal,
    RoleCreateModal,
    RoleEditModal,
  },
  data() {
    return {
      roleId: '',

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Name',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Description',
            key: 'description',
            sortable: false,
          },
          {
            ...this.$can('Edit', 'Roles') && {
              label: 'Action',
              key: 'action',
              stickyColumn: false,
            },
          },
        ],
      },
    }
  },
  async mounted() {
    await this.doFetchRoleOptions()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$store.commit('RoleOptions/setSelectedRoleId', this.roleId)

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const url = `roles/${this.roleId}`
        const response = await axios.get(url, axiosConfig)

        this.items = response.data.data.permissions

        // บันทึก Permission เข้า Storage เพื่อเอาไปกรองข้อมูล Permission
        await this.$store.commit('PermissionOptions/setPermissionIds', this.items)
        await this.$store.dispatch('PermissionOptions/fetchOptions')
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
      }
    },

    async doDeletePermission(permissionName) {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to delete this permission information?' })

      if (alertResult.value) {
        const body = { permission_names: [permissionName] }

        try {
          const response = await axios.post(`roles/${this.roleId}/removePermissions`, body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          await this.doLoadData()
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }
      }
    },

    async doFetchRoleOptions(roleId = '') {
      await this.$store.dispatch('RoleOptions/fetchOptions')

      // รับค่า roleId มาจาก $emit ของ Modal หรือถ้าไม่มีให้แทนค่าด้วย roleId ของ Option จากรายการแรก
      if (this.$store.getters['RoleOptions/getOptionsById'].length > 0) {
        this.roleId = roleId || this.$store.getters['RoleOptions/getOptionsById'][0].value
      }

      await this.doLoadData()
    }, // doFetchRoleOptions
  }, // methods
}
</script>
